<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $getByLang(body.name) }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 36px;
padding-bottom: 150px;"
    >
      <div class="container " v-html="$getByLang(body.body)">
        
        
      </div>
    </section>
    
    
  </div>
</template>

<script>

export default {
  data() {
    return {
      
      body: {
        name: null,
        body: null,
      },

     
    };
  },

  created() {
    const slug = this.$route.params.slug;
    if (slug) {
      this.$http.get(`page/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.body = res.data;
          }
        },
        (err) => {
          console.log(err);
        },
      );
     
     
     
    }
  },
  head: {
    // title: {
    //   inner: 'Offers',
    // },
    // Meta tags
    meta: [
      { name: 'application-name', content: 'Kleopatra Massage & Beauty' },
      {
        name: 'description',
        content: 'Kleopatra Massage & Beauty',
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: 'twitter:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        n: 'twitter:description',
        c: 'Kleopatra Massage & Beauty',
      },
      // ...
      // Google+ / Schema.org
      { itemprop: 'name', content: 'Kleopatra Massage & Beauty' },
      { itemprop: 'description', content: 'Kleopatra Massage & Beauty' },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: 'og:title', content: 'Kleopatra Massage & Beauty' },
      // with shorthand
      {
        p: 'og:image',
        c: 'https://kleopatramassage-beauty.com/image/logo.png',
      },
      // ...
    ],
  },
};
</script>

<style></style>
